<template>
  <section id="dashboard-section">
    <client-tabs
      v-if="role === 'admin'"
      :user-role="userRole"
    />
    <b-row
      v-if="role === 'admin1'"
    >
      <!-- GENERAL PREFRENCES SECTION -->
      <b-col
        lg="4"
        cols="12"
      >
        <b-card no-body>
          <b-card-body class="pl-0 pr-0">
            <h3 class=" ml-2">
              General
            </h3>
            <br>
            <b-list-group flush>
              <b-list-group-item
                v-for="(value, index) in preferencesLabel.general"
                :key="index"
                class="px-0"
              >
                <b-form-checkbox
                  v-model="preference.general[index]"
                  :value="preferencesLabel.general[index]"
                  class="ml-2"
                >
                  <span class="">
                    {{ preferencesLabel.general[index] }}
                  </span>
                </b-form-checkbox>
              </b-list-group-item>
            </b-list-group>
          </b-card-body>
        </b-card>
      </b-col>
      <!-- CARE EXPERIENCE -->
      <b-col
        lg="4"
        cols="12"
      >
        <b-card no-body>
          <b-card-body class="pl-0 pr-0">
            <h3 class=" ml-2">
              Care Experience
            </h3>
            <br>
            <b-list-group flush>
              <b-list-group-item
                v-for="(value, index) in preferencesLabel.careExperience"
                :key="index"
                class="px-0"
              >
                <b-form-checkbox
                  v-model="preference.careExperience[index]"
                  :value="preferencesLabel.careExperience[index]"
                  class="ml-2"
                >
                  <span class="">
                    {{ preferencesLabel.careExperience[index] }}
                  </span>
                </b-form-checkbox>
              </b-list-group-item>
            </b-list-group>
          </b-card-body>
        </b-card>
        <b-card no-body>
          <b-card-body class="pl-0 pr-0">
            <h3 class=" ml-2">
              Certifications
            </h3>
            <br>
            <b-list-group flush>
              <b-list-group-item
                v-for="(value, index) in preferencesLabel.certifications"
                :key="index"
                class="px-0"
              >
                <b-form-checkbox
                  v-model="preference.certification[index]"
                  :value="preferencesLabel.certifications[index]"
                  class="ml-2"
                >
                  <span class="">
                    {{ preferencesLabel.certifications[index] }}
                  </span>
                </b-form-checkbox>
              </b-list-group-item>
            </b-list-group>
          </b-card-body>
        </b-card>
      </b-col>
      <!-- CERTIFICATION PREFRENCES SECTION -->
      <b-col
        lg="4"
        cols="12"
      >
        <b-card no-body>
          <b-card-body class="pl-0 pr-0">
            <h3 class=" ml-2">
              Transfers
            </h3>
            <br>
            <b-list-group flush>
              <b-list-group-item
                v-for="(value, index) in preferencesLabel.transfers"
                :key="index"
                class="px-0"
              >
                <b-form-checkbox
                  v-model="preference.transfers[index]"
                  :value="preferencesLabel.transfers[index]"
                  class="ml-2"
                >
                  <span class="">
                    {{ preferencesLabel.transfers[index] }}
                  </span>
                </b-form-checkbox>
              </b-list-group-item>
            </b-list-group>
          </b-card-body>
        </b-card>
        <b-card no-body>
          <b-card-body class="pl-0 pr-0">
            <h3
              class=" ml-2"
            >
              Pets
            </h3>
            <br>
            <b-list-group flush>
              <b-list-group-item
                v-for="(value, index) in preferencesLabel.pets"
                :key="index"
                class="px-0"
              >
                <b-form-checkbox
                  v-model="preference.pets[index]"
                  :value="preferencesLabel.pets[index]"
                  class="ml-2"
                >
                  <span class="">
                    {{ preferencesLabel.pets[index] }}
                  </span>
                </b-form-checkbox>
              </b-list-group-item>
            </b-list-group>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        lg="12"
        class="mb-2 mt-4"
      >
        <b-button
          variant="secondary"
          type="submit"
          class="mr-1"
          @click="savePreferences"
        >
          Save
        </b-button>
        <b-button
          variant="outline-dark"
          type="cancel"
          @click="cancelPreferences"
        >
          Cancel
        </b-button>
      </b-col>
    </b-row>
    <b-row
      v-if="role === 'client' || role === 'admin'"
      id="client-preferences"
    >
      <b-col
        lg="12"
        cols="12"
      >
        <!-- <h2
          class="mb-3"
        >
          Caregiver Preferences
        </h2> -->
        <!-- <h5 class="mb-2">
          Indicate client's requirements in the following areas:
        </h5> -->
      </b-col>
      <!-- GENERAL PREFRENCES SECTION -->
      <b-col
        lg="12"
        cols="12"
      >
        <b-card no-body>
          <b-card-body class="pl-0 pr-0">
            <!-- GENERAL -->
            <h4 class=" ml-2 mb-2">
              <feather-icon
                icon="DiscIcon"
                class="text-primary=dark"
                size="16"
              />
              General
            </h4>
            <b-row>
              <b-col
                v-for="(value, index) in preferencesLabel.general"
                :key="index"
                lg="4"
              >
                <b-form-checkbox
                  v-model="preference.general[index]"
                  :value="preferencesLabel.general[index]"
                  class="ml-2 mb-2"
                >
                  <span class="">
                    {{ preferencesLabel.general[index] }}
                  </span>
                </b-form-checkbox>
              </b-col>
            </b-row>
            <br>
            <hr
              class="pl-2 pr-2"
              width="95%"
            >
            <!-- CARE EXPERIENCE -->
            <h4 class=" ml-2 mb-2">
              <img
                class="preferences-icon"
                src="@/assets/images/icons/care-exp-icon.svg"
                style="margin-top: -3px"
              >
              Care Experience
            </h4>
            <b-row>
              <b-col
                v-for="(value, index) in preferencesLabel.careExperience"
                :key="index"
                lg="4"
              >
                <b-form-checkbox
                  v-model="preference.careExperience[index]"
                  :value="preferencesLabel.careExperience[index]"
                  class="ml-2 mb-2"
                >
                  <span class="">
                    {{ preferencesLabel.careExperience[index] }}
                  </span>
                </b-form-checkbox>
              </b-col>
            </b-row>
            <br>
            <hr
              class="pl-2 pr-2"
              width="95%"
            >
            <!-- CERTIFICATIONS -->
            <h4 class=" ml-2 mb-2">
              <img
                class="preferences-icon"
                src="@/assets/images/icons/certifications-icon.svg"
                style="margin-top: -3px"
              >
              Certifications
            </h4>
            <b-row>
              <b-col
                v-for="(value, index) in preferencesLabel.certifications"
                :key="index"
                lg="4"
              >
                <b-form-checkbox
                  v-model="preference.certification[index]"
                  :value="preferencesLabel.certifications[index]"
                  class="ml-2 mb-2"
                >
                  <span class="">
                    {{ preferencesLabel.certifications[index] }}
                  </span>
                </b-form-checkbox>
              </b-col>
            </b-row>
            <br>
            <hr
              class="pl-2 pr-2"
              width="95%"
            >
            <!-- TRANSFERS -->
            <h4 class=" ml-2 mb-2">
              <i class="fa fa-wheelchair preferences-icon" />
              Transfers
            </h4>
            <b-row>
              <b-col
                v-for="(value, index) in preferencesLabel.transfers"
                :key="index"
                lg="4"
              >
                <b-form-checkbox
                  v-model="preference.transfers[index]"
                  :value="preferencesLabel.transfers[index]"
                  class="ml-2 mb-2"
                >
                  <span class="">
                    {{ preferencesLabel.transfers[index] }}
                  </span>
                </b-form-checkbox>
              </b-col>
            </b-row>
            <br>
            <hr
              class="pl-2 pr-2"
              width="95%"
            >
            <h4 class=" ml-2 mb-2">
              <img
                src="@/assets/images/icons/assessment-care/pets_form_icon.svg"
                style="margin-top: -3px"
                height="20px"
                width="20px"
              >
              Pets
            </h4>
            <b-row>
              <b-col
                v-for="(value, index) in preferencesLabel.pets"
                :key="index"
                lg="4"
              >
                <b-form-checkbox
                  v-model="preference.pets[index]"
                  :value="preferencesLabel.pets[index]"
                  class="ml-2 mb-2"
                >
                  <span class="">
                    {{ preferencesLabel.pets[index] }}
                  </span>
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-col
              lg="12"
              class="mb-2 mt-4"
            >
              <b-button
                variant="secondary"
                type="submit"
                class="mr-1"
                @click="savePreferences"
              >
                Save
              </b-button>
              <b-button
                variant="outline-dark"
                type="cancel"
                @click="cancelPreferences"
              >
                Cancel
              </b-button>
            </b-col>
          </b-card-body>
        </b-card>
      </b-col>
      <!-- <b-modal
        id="deactivateModal"
        ref="my-modal"
        title="Deactivate"
        ok-title="Deactivate"
        centered
        cancel-variant="outline-secondary"
        ok-variant="danger"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
      >
        <form
          ref="form"
          @submit.stop.prevent="handleSubmit"
        >
          <b-card-text class="mt-2">
            You are about to activate the client account of <strong> {{ client }} </strong> Select the reason for deactivation below:
          </b-card-text>
          <b-form-group
            :state="nameState"
            label=""
            class="mt-2"
            label-for="name-input"
            invalid-feedback="Name is required"
          >
            <b-form-group label="">
              <b-form-radio-group
                v-model="deactivationReason"
                :options="options"
                class="mb-0"
                name="radios-stacked"
                stacked
              />
            </b-form-group>
            <div class="ml-2">
              <b-form-input
                v-if="deactivationReason == 'Other'"
                id="name-input"
                v-model="otherOption"
                class="mt-0"
                :state="nameState"
                required
              />
            </div>
          </b-form-group>
        </form>
      </b-modal> -->
    </b-row>
  </section>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BCard, BCardBody, BListGroup, BListGroupItem, BFormCheckbox, BButton,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import Swal from 'sweetalert2'
import ClientTabs from './ClientTabs.vue'
import clientStoreModule from './js/clientStoreModule'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BListGroup,
    BListGroupItem,
    BFormCheckbox,
    BButton,
    ClientTabs,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      id: this.$route.query.id,
      data: {},
      preference: {
        general: [],
        certification: [],
        careExperience: [],
        transfers: [],
        pets: [],
      },
      mainUrl: this.$mainUrl,
      preferencesLabel: {
        general: [
          'Prefers Female Caregiver',
          'Prefers Male Caregiver',
          'Insured Automobile',
          'Live-In Shifts',
          'Ok with Client Smoking',
          'Speak Client Language',
        ],
        careExperience: [
          'Dementia',
          'Hospice',
          'Critical (ex: Stroke, Cancer, Major Organ Transplant)',
        ],
        certifications: [
          'HCA License',
          'HHA License',
          'CNA License',
          'RN/LVN License',
        ],
        transfers: [
          'WheelChair Experience',
          'Hoyer Experience',
        ],
        pets: [
          'Ok with Cats',
          'Ok with Dogs',
        ],
      },
      role: '',
    }
  },
  created() {
    // eslint-disable-next-line
    this.getPreferences()
    const userData = JSON.parse(localStorage.userData)
    this.role = userData.role
    this.id = this.$route.query.id
    const user = JSON.parse(localStorage.getItem('userData'))
    this.userRole = user.role
    if (this.userRole !== 'admin') {
      this.id = user.clientId
    }
  },
  setup() {
    const CLIENT_APP_STORE_MODULE_NAME = 'app-client'

    // Register module
    if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.registerModule(CLIENT_APP_STORE_MODULE_NAME, clientStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.unregisterModule(CLIENT_APP_STORE_MODULE_NAME)
    })
  },
  methods: {
    savePreferences() {
      if (this.role === 'client' || this.role === 'admin') {
        store
          .dispatch('app-client/savePreferences', { id: this.id, preferences: this.preference })
          // eslint-disable-next-line no-unused-vars
          .then(response => {
            Swal.fire({
              icon: 'success',
              title: 'Updated Succesfully',
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
          .catch(error => {
            console.error(error)
          })
      }
    },
    cancelPreferences() {
      this.getPreferences()
    },
    getPreferences() {
      const userData = JSON.parse(localStorage.userData)
      this.role = userData.role
      if (this.role === 'admin') {
        console.log('admin')
      }
      if (this.role === 'admin') {
        this.id = this.$route.query.id
      } else if (this.role === 'client') {
        this.id = userData.clientId
      }
      store
        .dispatch('app-client/getPreferences', { id: this.id })
        .then(response => {
          // eslint-disable-next-line
          const obj = JSON.parse(response.data.response.data['preferences'][0]['preference'])
          this.preference.general = obj.general
          this.preference.certification = obj.certification
          this.preference.transfers = obj.transfers
          this.preference.pets = obj.pets
          this.preference.careExperience = obj.careExperience
        })
        .catch(error => {
          console.error(error)
        })
    },
  },
}
</script>

<style>
h1{
  margin: auto;
}
.list-group-item.px-0 {
    height: 55px;
}
#textarea-notes{
    background: #F7F9FB 0% 0% no-repeat padding-box !important;
    border: 0px solid lightgray !important;
}
.family-border{
  border: 1px solid#569099 !important;
}
.preferences-icon{
  height: 18px!important;
  width: 18px!important;
}
</style>
<style lang="scss" scope>

@import "@core/scss/vue/apps/calendar.scss";
// used in calendar mail icon
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');
</style>
